export default function FormInput({ children, type, placeholder, title, id }) {
    const helpId = id + "Help";

    return (
        <div className="form-group">
            <label htmlFor={id}>{title}</label>
            <input type={type} className="form-control" id={id} aria-describedby={helpId}
                   placeholder={placeholder}/>
            <small id={helpId} className="form-text text-muted">
                {children}
            </small>
        </div>
    );
}
