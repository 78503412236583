import FormButton from "../FormButton/FormButton";
import FormInput from "../FormInput/FormInput";
import data from "../../data"
import "./RegisterForm.css"
import axios from "axios";
import {useState} from "react";

export default function RegisterForm() {
    const [emailRequestLocked, setEmailRequestLocked] = useState(false);
    const [registerButtonLocked, setRegisterButtonLocked] = useState(false);
    const [secondsCounter, setSecondsCounter] = useState(30);

    function emailSendingHandler() {
        if (emailRequestLocked) {
            return;
        }
        setEmailRequestLocked(true);
        const email = document.getElementById("emailInput").value;

        // check email
        if (email === undefined || email === "") {
            setEmailRequestLocked(false);
            console.log("invalid email address");
            return;
        }

        // deactivate button
        const emailSentMessageElement = document.getElementsByClassName("email-send-success").item(0);
        const emailSentErrorElement = document.getElementsByClassName("email-send-error").item(0);
        const emailSentProcessElement = document.getElementsByClassName("email-send-process").item(0);
        const emailLinkElement = document.getElementsByClassName("send-email-link").item(0);
        const messages = document.getElementsByClassName("email-sent-message");

        emailLinkElement.classList.remove("send-email-link-active");
        emailSentProcessElement.classList.add("email-sent-message-active");

        // send request
        axios.post(
            data.api_endpoint + "/accounts/request-code/",
            {email: email},
        ).then((response) => {
            emailSentMessageElement.classList.add("email-sent-message-active");
            console.log("sent request to " + data.api_endpoint);
        }).catch((error) => {
            emailSentErrorElement.classList.add("email-sent-message-active");
            console.log(error);
        }).finally(() => {
            emailSentProcessElement.classList.remove("email-sent-message-active");
            const seconds = 30;
            let secondsPassed = 1;
            const interval = setInterval(() => {
                setSecondsCounter(seconds - secondsPassed++);
            }, 1_000)
            setTimeout(() => {
                for (let i = 0; i < messages.length; i++) {
                    messages.item(i).classList.remove("email-sent-message-active");
                }
                emailLinkElement.classList.add("send-email-link-active");
                setEmailRequestLocked(false);
                clearInterval(interval);
            }, 30_000);
        });
    }

    function registerButtonHandler() {
        if (registerButtonLocked) {
            return;
        }
        setRegisterButtonLocked(true);

        const email = document.getElementById("emailInput").value;
        const username = document.getElementById("usernameInput").value;
        const password = document.getElementById("passwordInput").value;
        const password2 = document.getElementById("passwordInput2").value;
        const token = document.getElementById("tokenInput").value;

        const alertSuccess = document.querySelector(".form-alert-success");
        const alertError = document.querySelector(".form-alert-error");

        // check email
        if (email === undefined || email === "") {
            setRegisterButtonLocked(false);
            console.log("invalid email address");
            return;
        }

        // username check
        if (username === undefined || username === "") {
            setRegisterButtonLocked(false);
            console.log("invalid username");
            return;
        }

        // password checks
        if (password === undefined || password === "") {
            setRegisterButtonLocked(false);
            console.log("invalid password");
            return;
        }
        if (password !== password2) {
            setRegisterButtonLocked(false);
            console.log("passwords not match");
            return;
        }

        // token check
        if (token === undefined || token === "") {
            setRegisterButtonLocked(false);
            console.log("invalid token");
            return;
        }

        axios.post(
            data.api_endpoint + "/accounts/",
            {
                email: email,
                username: username,
                password: password,
            },
            {
                headers: {
                    "Content-Type": "application/json",
                    "authorization": "Bearer " + token,
                }
            }
        ).then((response) => {
            alertError.classList.remove("form-alert-active");
            alertSuccess.classList.add("form-alert-active");
            console.log("sent request to " + data.api_endpoint);
        }).catch((error) => {
            alertSuccess.classList.remove("form-alert-active");
            alertError.classList.add("form-alert-active");
        }).finally(() => {
            setTimeout(() => {
                setRegisterButtonLocked(false);
                alertSuccess.classList.remove("form-alert-active");
                alertError.classList.remove("form-alert-active");
            }, 5_000);
        });
    }

    return (
        <form className="register-form">
            <FormInput type="email" id="emailInput" placeholder="Email" title="Адрес электронной почты">
                Обязательно укажите почту, к которой у вас есть доступ, так как туда придёт письмо подтверждения.
            </FormInput>

            <br/>

            <FormInput title="Имя пользователя" type="text" id="usernameInput" placeholder="Username">
                Под этим именем вас будут видеть другие.
            </FormInput>

            <br/>

            <FormInput title="Пароль" type="password" id="passwordInput" placeholder="Password"></FormInput>

            <br/>

            <FormInput title="Повторите пароль" type="password" id="passwordInput2" placeholder="Password"></FormInput>

            <br/>

            <FormInput title="Токен подтверждения" type="text" id="tokenInput" placeholder="Token">
                Токен подтверждения, который должен прийти на ваш электронный адрес.&nbsp;
                <span onClick={emailSendingHandler}
                      className="send-email-link send-email-link-active">Отправить письмо</span>
                <span className="email-sent-message email-send-process">Отправляем!</span>
                <span className="email-sent-message email-send-success">Письмо отправлено! Ещё одно можно будет отправить через {secondsCounter} сек.</span>
                <span className="email-sent-message email-send-error">Ошибка при отправке письма! Ещё одно можно будет отправить через {secondsCounter} сек.</span>
            </FormInput>

            <br/>

            <FormButton disabled={registerButtonLocked} onClick={registerButtonHandler}>Зарегистрировать
                аккаунт</FormButton>

            <div className="alert alert-success form-alert form-alert-success" role="alert">
                Аккаунт успешно зарегистрирован!
            </div>
            <div className="alert alert-danger form-alert form-alert-error" role="alert">
                Что-то пошло не так, проверьте вводимые данные!
            </div>
        </form>
    );
}
