import './App.css';
import RegisterForm from "./components/RegisterForm/RegisterForm";

export default function App() {
  return (
    <>
      <RegisterForm />
    </>
  );
}
